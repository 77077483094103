import Isotope from 'isotope-layout';
import Flickity from 'flickity';
import LazyLoad from 'vanilla-lazyload';


// helpers

const debounce = (fn, threshold) => {
  var timeout;
  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayed = () => {
      fn();
      timeout = null;
    }
    timeout = setTimeout(delayed, threshold || 100);
  }
}


// site nav

const siteHeader = document.querySelector('.site-header');
const siteNavToggle = document.querySelector('.site-nav-toggle');
const siteNavLinks = document.querySelectorAll('.site-nav a');
const toggleSiteNavClass = () => {
  siteHeader.classList.toggle('site-nav-is-open');
}
siteNavToggle.addEventListener('click', toggleSiteNavClass);
siteNavLinks.forEach((siteNavLink) => {
  siteNavLink.addEventListener('click', toggleSiteNavClass);
});


// isotope

const folioItems = document.querySelector('.folio-items');
if (folioItems) {
  let hash;
  let filters;

  const isoOptions = {
    itemSelector: '.folio-item',
    filter: (item) => {
      for (const [filter, filterValue] of filters) {
        if (filterValue === '') {
          continue;
        }

        let match = false;
        if (filter === 'filter') {
          let title = item.querySelector('.folio-item-title').innerHTML;
          if (title.search(new RegExp(filterValue, 'i')) !== -1) {
            match = true;
          }
        } else {
          let itemValues = item.dataset[filter];
          if (itemValues) {
            itemValues = itemValues.split(',');
            for (const itemValue of itemValues) {
              if (itemValue === filterValue) {
                match = true;
                break;
              }
            }
          }
        }

        if (!match) {
          return false;
        }
      }
      return true;
    },
  };

  const folioFilters = document.querySelectorAll('.folio-filter');
  const folioPageTitle = document.getElementById('folio-page-title');
  const folioFilterBy = document.getElementById('folio-filter-by');
  const onFolioFilterByChange = debounce(() => {
    let newHash = '';

    for (const [filter, filterValue] of filters) {
      // skip the 'filter' as we will add the updated one later
      if (filter === 'filter') {
        continue;
      }

      if (newHash !== '') {
        newHash += '&';
      }
      newHash += filter + '=' + filterValue;
    }

    // add new 'filter'
    if (folioFilterBy.value !== '') {
      if (newHash !== '') {
        newHash += '&';
      }
      newHash += 'filter=' + folioFilterBy.value;
    }

    location.hash = newHash;
  }, 200);
  folioFilterBy.addEventListener('keyup', onFolioFilterByChange);

  const updateFilters = () => {
    // set hash and filters variables
    hash = location.hash.replace('#', '');
    filters = new URLSearchParams(hash);

    // update filter links with active class
    folioFilters.forEach((item) => item.classList.remove('active'));
    if (hash) {
      folioFilters.forEach((item) => {
        const itemHash = item.href.split('#')[1];
        if (decodeURIComponent(hash).includes(decodeURIComponent(itemHash))) {
          item.classList.add('active');
        }
      });
    }

    // update folio page title
    let folioPageTitleHTML = '';
    for (const [filter, filterValue] of filters) {
      if (filter !== 'filter') {
        folioPageTitleHTML = `
          <h1 class="breadcrumb-heading"><strong>${ filterValue }</strong></h1>
          <div class="breadcrumbs">
            <a href="/folio/#">Folio</a>
          </div>
        `;
        break;
      }
    }
    if (folioPageTitleHTML === '') {
      folioPageTitleHTML = '<h1><strong>Folio</strong></h1>';
    }
    folioPageTitle.innerHTML = folioPageTitleHTML;

    // update filter by field value
    let filter = hash.match(/filter=([^&]+)/i);
    if (filter) {
      folioFilterBy.value = decodeURIComponent(filter[1]);
    } else {
      folioFilterBy.value = '';
    }
  }

  updateFilters();
  const iso = new Isotope(folioItems, isoOptions);

  const onHashChange = () => {
    updateFilters();
    iso.arrange(isoOptions);
  }
  window.addEventListener('hashchange', onHashChange);
}


// slider

const slider = document.querySelector('.slider');
if (slider) {
  const flkty = new Flickity(slider, {
    prevNextButtons: false,
    autoPlay: 5000,
    pauseAutoPlayOnHover: false,
  });
}


// lazyload

const myLazyLoad = new LazyLoad();
